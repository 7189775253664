<template>
    <main role="main" class="page-content">
        <div class="subheader hidden-lg-up d-flex">
            <portal to="ariane">
                <h1 class="subheader-title mb-2 text-blue-600">
                    {{ $t('template.create') }}
                </h1>
            </portal>
        </div>
        <div>
            <button
                type="button"
                @click="addTemplate"
                class="m-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
                {{ $t('template.add') }}
            </button>
        </div>
        <div
            v-if="!loading"
            class="grid sm:grids-cols-1"
            :class="templates.length === 1 ? 'md:grid-cols-1' : 'md:grid-cols-2'"
        >
            <AddTemplate
                v-for="template in templates"
                :type="templates.length == 1 ? 'w-1/2' : 'w-full'"
                :space="templates.length == 1 ? '' : 'p-4'"
                :formOptions="template.formOptions"
                :key="`template${template.id}`"
            />
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AddTemplate from '@/components/Model/Admin/Template/AddTemplate'

export default {
    name: 'CreateTemplate',
    components: { AddTemplate },
    data() {
        return {
            loading: true,
            formOptions: [],
            templates: []
        }
    },
    methods: {
        ...mapActions('form', ['getForms']),
        addTemplate() {
            this.templates.push({
                id: this.templates.length + 1,
                formOptions: this.formOptions,
            })
        }
    },
    created() {
        this.getForms().then(() => {
            this.loading = false
            this.forms.forEach((form) => {
                let translationName
                form.translations.forEach((translation) => {
                    if (translation.locale === this.$i18n.locale) {
                        translationName = translation.name
                    }
                })
                this.formOptions.push({
                    id: form.id,
                    name: translationName
                })
            })
            this.addTemplate()
        })
    },
    computed: {
        ...mapState('form', {
            forms: (state) => state.forms
        }),
    }
}
</script>
