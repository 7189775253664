<template>
    <div class="flex flex-column items-center" :class="space">
        <section
            class="p-5 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white flex-column flex justify-center"
            :class="type"
        >
            <form class="space-y-4">
                <div class="text-blue-600 text-xl font-bold">
                    {{ $t('template.reference').toUpperCase() }}
                </div>
                <div class="flex border-gray-300 border rounded">
                    <input
                        v-model="reference"
                        class="focus:ring-blue-500 rounded sm:text-md border-0"
                        type="text"
                        id="reference"
                    />
                </div>
                <div
                    v-if="referenceError"
                    class="text-red-500 text-sm font-bold text-center"
                >
                    {{ referenceError }}
                </div>
                <div class="text-blue-600 text-xl font-bold">
                    {{ $t('template.subject').toUpperCase() }}
                </div>
                <div class="flex border-gray-300 border rounded">
                    <input
                        v-model="subject"
                        class="focus:ring-blue-500 border-0 rounded sm:text-md"
                        type="text"
                        id="subject"
                    />
                </div>
                <div
                    v-if="subjectError"
                    class="text-red-500 text-sm font-bold text-center"
                >
                    {{ subjectError }}
                </div>
                <div class="text-blue-600 text-xl font-bold">
                    {{ $t('template.form').toUpperCase() }}
                </div>
                <div class="flex border-gray-300 border rounded">
                    <select
                        id="form"
                        name="form"
                        v-model="selectedForm"
                        class="mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none sm:text-md rounded-md"
                    >
                        <option
                            v-for="option in formOptions"
                            :key="option.id"
                            :value="option.id"
                            class="py-2"
                        >
                            {{ option.name }}
                        </option>
                    </select>
                </div>
                <div
                    v-if="selectedFormError"
                    class="text-red-500 text-sm font-bold text-center"
                >
                    {{ selectedFormError }}
                </div>
                <div class="text-blue-600 text-xl font-bold">
                    {{ $t('template.model').toUpperCase() }}
                </div>
                <div class="border-gray-300 border rounded w-full">
                    <ckeditor
                        v-model="template"
                        class="w-full focus:ring-blue-500 focus:border-blue-500 text-md border-0 rounded-md"
                        :config="editorConfig"
                        :editor="editor"
                    ></ckeditor>
                </div>
                <div
                    v-if="templateError"
                    class="text-red-500 text-sm font-bold text-center"
                >
                    {{ templateError }}
                </div>
                <div
                    class="flex inline-flex justify-end w-full items-center space-x-5"
                >
                    <button
                        v-if="!isDisabled"
                        @click.prevent="cancel"
                        type="button"
                        class="inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                        {{ $t('template.delete.cancel') }}
                    </button>
                    <button
                        :disabled="isDisabled"
                        :class="isDisabled ? 'disabled' : ''"
                        @click.prevent="save"
                        type="button"
                        class="inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        {{ $t('template.save') }}
                    </button>
                </div>
            </form>
        </section>
    </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'AddTemplate',
    props: ['formOptions', 'type', 'space'],
    data() {
        return {
            loading: true,
            subject: null,
            reference: null,
            template: null,
            selectedForm: null,
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'bold',
                        'italic',
                        'link',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'undo',
                        'redo'
                    ]
                }
            },
            isDisabled: false,
            referenceError: null,
            subjectError: null,
            templateError: null,
            selectedFormError: null
        }
    },
    methods: {
        displayHtml(message) {
            return message
                .replace(/&lt;/g, '<')
                .replace(/&gt;/g, '>')
                .replace(/&amp;/g, '&')
                .replace(/<br\s*[\/]?>/gi, '')
        },
        onChange() {
            if (
                this.template.includes('&lt;img') &&
                this.template.includes('&gt;')
            ) {
                let images = this.template.match(/&lt;img.*?&gt;/g)
                for (let i = 0; i < images.length; i++) {
                    this.template = this.template.replace(
                        images[i],
                        this.displayHtml(images[i])
                    )
                    const changeContent = new Promise((resolve, reject) => {
                        this.template = this.template.replace(
                            images[i],
                            this.displayHtml(images[i])
                        )
                        resolve()
                    })

                    changeContent.finally(() => {
                        const src = this.template.match(/src="(.*?)"/)[1]
                        const style = this.template.match(/style="(.*?)"/)
                            ? this.template.match(/style="(.*?)"/)[1]
                            : ''
                        const width = this.template.match(/width="(.*?)"/)
                            ? this.template.match(/width="(.*?)"/)[1]
                            : ''
                        const height = this.template.match(/height=(.*?)"/)
                            ? this.template.match(/height=(.*?)"/)[1]
                            : ''
                        let image = document.querySelector(
                            'img[src="' + src + '"]'
                        )
                        image.setAttribute(
                            'style',
                            style + ` width: ${width}; ` + `height: ${height};`
                        )
                    })
                }
            }
            if (
                this.template.includes('&lt;a') &&
                this.template.includes('&lt;/a&gt;')
            ) {
                let links = this.template.match(/&lt;a.*?&lt;\/a&gt;/g)
                for (let i = 0; i < links.length; i++) {
                    this.template = this.template.replace(
                        links[i],
                        this.displayHtml(links[i])
                    )
                }
            }
        },
        cancel() {
            this.$router.go(-1)
        },
        save() {
            if (!this.template) {
                this.templateError = this.$t('template.field_error')
            } else {
                this.templateError = null
            }
            if (!this.reference) {
                this.referenceError = this.$t('template.field_error')
            } else {
                this.referenceError = null
            }
            if (this.template && this.reference) {
                this.postTemplate({
                    form_id: this.selectedForm,
                    response_title: this.reference,
                    response_content: this.template,
                    response_subject: this.subject
                }).then(() => {
                    this.isDisabled = true
                    this.$store.dispatch('toast', {
                        type: 'success',
                        title: 'Sauvegarde effectuée'
                    })
                })
            }
        },
        ...mapActions('admin', ['postTemplate'])
    },
    computed: {
        ...mapState('admin', {
            templates: (state) => state.templates
        })
    }
}
</script>
